export const ERRORS_REG = {
  400: "Некорректно заполнено одно из полей",
};

export const ERRORS_LOGIN = {
  400: "Неправильно заполнено одно из полей",
  401: "Пользователь не найден",
};

export const ERRORS_CHECK = {
  400: "Токен не передан или передан не в том формате",
  401: "Переданный токен некорректен",
};

export const REG_STATUS_OK = "Вы успешно зарегистрировались!";
export const ERROR = "Что-то пошло не так! Попробуйте ещё раз.";
